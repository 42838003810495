@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

.about-body{
    display: flex;
    flex-direction: column;
    align-items: center;    
    max-width: 1200px;    
    /* border: 5px solid red */
    font-family: 'Open Sans', sans-serif;
    padding: 0px 20px;
}

.content{
    display: flex;
    justify-content: center;
}

.about-body img{
    border-radius: 12px;
    height: 30rem;
    box-shadow: 10px 10px 5px rgb(176, 182, 184);
}
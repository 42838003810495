@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

.navbar-body{
    /* border: 2px solid pink; */
    display: flex;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
}

.navbar{        
    /* border: 2px solid red; */
    display: flex;
    align-items: center;
    padding: 0px 20px;
    max-width: 1200px;
    flex-grow: 2;
}

.menu-items{        
    /* border: 2px solid black; */
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    min-width: 450px;    
}

.menu-items a{            
    color: black;
}

.menu-burger{
    display: none;
    margin-left: auto;
}

.logo{
    width: 60px;   
}

.nav-cover{
    position: fixed;    
    width: 100vw;
    height: 120vh;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.767);
    color: white;
    font-family: 'Open Sans', sans-serif;    
}

.nav-cover-menu{
    text-align: right;    
}

.nav-cover-menu svg{    
    margin: 60px 30px;    
}

.nav-cover-list{
    display: flex;
    flex-direction: column;
    font-size: 2.1em;
    height: 50vh;
    /* border: 2px solid white; */
    justify-content: space-around;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .menu-burger{        
        display:inline;
        margin-left: auto;   
        /* border: 2px solid red; */        
    }    
    .menu-items{        
        display: none;
    }
  }

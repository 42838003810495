@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

.banner-body{
    display: flex;
    justify-content: center;
    background-color: #FF3300;
}

.banner{    
    display: flex;
    align-items: center;
    background-color: #FF3300;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    padding: 0px 20px;
    height: 3rem;
    max-width: 1200px;
    flex-grow: 2;
}
.social-media{
    display: flex;
    justify-content: space-around;
    min-width: 150px;
    /* border: 2px solid black; */
    margin-left: auto;
}
.whatsapp-imglink{
    /* border: 4px solid red;    */
    position: fixed;
    z-index: 100;
    right: 20px;
    bottom: 20px;
}

.whatsapp-img{
    /* border: 3px dotted blue ; */
    box-shadow: 20px 20px 70px grey;
    height: 50px;
    width: 50px;

}